export const LOGIN = 'LOGIN';
export const REGISTER = 'REGISTER';
export const REGISTER_BEGIN = 'REGISTER_BEGIN';
export const REGISTER_FAILED = 'REGISTER_FAILED';
export const LOGOUT = "LOGOUT";
export const SET_ROLE = "SET_ROLE";

export const PRODUCT_LIST = "PRODUCT_LIST";
export const REGISTER_PRODUCTS = "REGISTER_PRODUCTS";
export const UPDATE_PRODUCTS = "UPDATE_PRODUCTS";
export const DELL_PRODUCTS = "DELL_PRODUCTS";

export const USERS = "USERS";

export const CART_ADD_PRODUCT = "CART_ADD_PRODUCT";
export const CART_CLEAR = "CART_CLEAR";
export const CART_LOAD_PRODUCT = "CART_LOAD_PRODUCT";
export const CART_PLUS_PRODUCT = "CART_PLUS_PRODUCT";
export const CART_MINUS_PRODUCT = "CART_MINUS_PRODUCT";
//export const CART_DEL_ITEM_PRODUCT = "CART_DEL_ITEM_PRODUCT";

export const CHECKOUT = "CHECKOUT";
export const ORDERS = "ORDERS";
export const CHACK_STATUS = "CHACK_STATUS";
export const ORDERITEMS = "ORDERITEMS";
