import axios from "axios";

export const urlBackend = 'https://iriska.pp.ua/';

//export const urlBackend = "http://localhost:5050/";
export default axios.create({
  baseURL: `${urlBackend}`,
  headers: {
    "Content-type": "application/json",
  },
});
